import moment from 'moment';
import Decimal from 'decimal.js';
import { Balance } from 'src/app/store/slices/balance/types';
import { Exchange } from 'src/app/store/slices/exchanges/types';
import { Assets, Networks } from 'src/app/store/slices/assets/types';
import { SubAccountType, SubAccountAsset } from 'src/app/store/slices/sub-accounts/types';
import {
  DateRange,
  DetailError, EDateRange, EEnvironment, ETradingType, Nullable, SubAccountsModify,
} from 'src/shared/types/global-types';
import { store } from 'src/app/store/store';
import { START_PAGE, USDT_PRECISION } from 'src/shared/constants/constants';
import { Location } from 'react-router-dom';
import SupportService from 'src/services/support.service';
import { TELEGRAM_BOT_SUPPORT } from 'src/shared/constants/app-links';
import { batch } from 'react-redux';
import { setAuth, setSessionId } from 'src/pages/auth/login/model/slice';
import { clearUserSlice } from 'src/app/store/slices/user/slice';
import { clearSubsciptionQueue } from 'src/app/store/slices/subscriptions/slice';
import { setHandleConnectedWebSocket } from 'src/app/store/slices/alert/slice';
import { clearNotificationsSlice } from 'src/widgets/notifications/model/slice';
import { clearSubAccountsSlice } from 'src/app/store/slices/sub-accounts/slice';
import { removeExchanges } from 'src/app/store/slices/exchanges/slice';
import { clearBalancesSlice } from 'src/app/store/slices/balance/slice';
import { clearRebateSlice } from 'src/app/store/slices/bonus/slice';
import { clearTradingSettingsSlice } from 'src/pages/settings/model/slice';
import { clearTransfersSlice } from 'src/widgets/transfers/model/slice';
import { clearAssetsSlice } from 'src/app/store/slices/assets/slice';
import { clearTradeListSlice } from 'src/pages/diary/pages/trade-list/model/slice';
import { TradeList } from 'src/pages/diary/pages/trade-list/model/types';
import { getTokensFromLocalStorage } from './save-tokens';

export const join = (...args: (boolean | undefined | string)[]) => args.filter((e) => e).join(' ');

export const sleep = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms); });

export const hasNumber = (str: string) => /\d/.test(str);

export const getRandomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const checkFullPhone = (fullPhone: any) => /^\d{9,}$/.test(fullPhone);

export const saveToLocalStorage = (key: string, value: any): void => {
  const valueJSON = JSON.stringify(value);

  try {
    localStorage.setItem(key, valueJSON);
  } catch (error) {
    console.error(`Failed to save ${key} to localStorage: ${error}`);
  }
};

export const getFromLocalStorage = <T>(key: string, defaultValue: T): T => {
  try {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue) as T;
    }
  } catch (error) {
    console.error(`Failed to get ${key} from localStorage: ${error}`);
  }

  return defaultValue;
};

export const removeTokensFromLocalStorage = () => {
  if ('localStorage' in window) {
    if (localStorage.getItem('accessToken')) {
      localStorage.removeItem('accessToken');
    }
    if (localStorage.getItem('refreshToken')) {
      localStorage.removeItem('refreshToken');
    }
  } else {
    console.error('localStorage is not available');
  }
};

export const removeItemFromLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing item with key "${key}" from localStorage:`, error);
  }
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): { execute: (...args: Parameters<T>) => void; stop: () => void } => {
  let timeout: ReturnType<typeof setTimeout>;

  const execute = (...args: Parameters<T>) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };

  const stop = () => {
    clearTimeout(timeout);
  };

  return { execute, stop };
};

export const sortAssets = (a: SubAccountAsset, b: SubAccountAsset) => {
  const sortOrder = ['usdt', 'usdc', 'btc', 'eth', 'bnb'];

  const indexA = sortOrder.indexOf(a.asset.symbol.toLowerCase());
  const indexB = sortOrder.indexOf(b.asset.symbol.toLowerCase());

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.asset.symbol.localeCompare(b.asset.symbol);
};

export const sortNetworksAssets = (a: Assets, b: Assets) => {
  const sortOrder = ['usdt', 'usdc', 'btc', 'eth', 'bnb'];

  const indexA = sortOrder.indexOf(a.asset.symbol.toLowerCase());
  const indexB = sortOrder.indexOf(b.asset.symbol.toLowerCase());

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return a.asset.symbol.localeCompare(b.asset.symbol);
};

export const sortNetworksUsdt = (a: Networks, b: Networks): number => {
  const usdtSymbolOrder = ['TRX', 'ETH', 'BSC'];

  const indexA = usdtSymbolOrder.indexOf(a.network.symbol);
  const indexB = usdtSymbolOrder.indexOf(b.network.symbol);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  } if (indexA !== -1) {
    return -1;
  } if (indexB !== -1) {
    return 1;
  }

  if (a.asset_network.is_default && !b.asset_network.is_default) {
    return -1;
  } if (!a.asset_network.is_default && b.asset_network.is_default) {
    return 1;
  }

  return a.network.symbol.localeCompare(b.network.symbol);
};

export const sortNetworks = (a: Networks, b: Networks) => {
  if (a.asset_network.is_default && !b.asset_network.is_default) {
    return -1;
  } if (!a.asset_network.is_default && b.asset_network.is_default) {
    return 1;
  }
  return a.network.symbol.localeCompare(b.network.symbol);
};

export const sortSubAccounts = (a: SubAccountType, b: SubAccountType) => {
  if (a.exchange_id !== b.exchange_id) {
    return a.exchange_id - b.exchange_id;
  }
  return a.id - b.id;
};

export const sortSubAccountsByFavorite = (a: SubAccountType, b: SubAccountType) => {
  // Первичная сортировка по свойству is_favorite
  if (a.is_favorite && !b.is_favorite) {
    return -1; // a должен быть выше b
  } if (!a.is_favorite && b.is_favorite) {
    return 1; // b должен быть выше a
  }

  // Вторичная сортировка по exchange_id в порядке убывания
  if (a.exchange_id !== b.exchange_id) {
    return a.exchange_id - b.exchange_id;
  }

  // Третичная сортировка по user_name в алфавитном порядке
  return a.user_name.localeCompare(b.user_name);
};

export const toFixed = (value: string | undefined | null, precision: number): string => {
  if (value === undefined || value === null) {
    return '';
  }

  if (Number.isInteger(Number(value))) {
    return value;
  }

  const [integer, fraction = ''] = value.split('.');

  if (fraction.length <= precision) {
    return value;
  }

  return parseFloat(`${integer}.${fraction.slice(0, precision) || '0'}`).toString();
};

export const getSpotWalletSubAccount = (id: number, balances: Nullable<Balance[]>) => {
  if (balances) {
    const balance = balances.find((balance) => balance.sub_account_id === id);
    return balance ? toFixed(balance.spot, 1) : undefined;
  }
  return undefined;
};

export const multiplyValues = (valueA: string, valueB: string, precision?: number): string => {
  if (valueA.trim() === '' || valueB.trim() === '') return '';
  if (!Number.isFinite(Number(valueA)) || !Number.isFinite(Number(valueB))) return '';

  if (precision && precision === 1) {
    return new Decimal(valueA).times(valueB).toFixed(precision, 1);
  }

  if (precision) {
    return new Decimal(valueA).times(valueB).toFixed(precision, 1);
  }
  return new Decimal(valueA).times(valueB).toString();
};

export const divideValues = (valueA: string, valueB: string, precision?: number): string => {
  if (typeof valueA !== 'string' || typeof valueB !== 'string') return '0';

  if (valueA.trim() === '' || valueB.trim() === '') return '';
  if (!Number.isFinite(Number(valueA)) || !Number.isFinite(Number(valueB))) return '';

  if (precision) {
    return new Decimal(valueA).dividedBy(valueB).toFixed(precision, 1);
  }

  if (precision && precision === 2) {
    return new Decimal(valueA).dividedBy(valueB).toFixed(precision, 1);
  }

  return new Decimal(valueA).dividedBy(valueB).toString();
};

export const minusValues = (valueA: string, valueB: string, precision?: number) => {
  if (typeof valueA !== 'string' || typeof valueB !== 'string') return '0';
  if (valueA.trim() === '' || valueB.trim() === '') return '0';
  if (!Number.isFinite(Number(valueA)) || !Number.isFinite(Number(valueB))) return '0';

  if (precision) return new Decimal(valueA).minus(valueB).toFixed(precision, 1);

  return new Decimal(valueA).minus(valueB).toString();
};

export const plusValues = (valueA: string, valueB: string, precision?: number) => {
  if (typeof valueA !== 'string' || typeof valueB !== 'string') return '0';
  if (valueA.trim() === '' || valueB.trim() === '') return '0';
  if (!Number.isFinite(Number(valueA)) || !Number.isFinite(Number(valueB))) return '0';

  if (precision) return new Decimal(valueA).plus(valueB).toFixed(precision, 1);

  return new Decimal(valueA).plus(valueB).toString();
};

export const mapNumberToAssetType = (number: number) => (number === 2 ? 'F' : 'S');

export const decimalizeQuantity = (quantity: string | number, symbol?: string) => {
  const formattedUSDT = new Decimal(String(quantity)).toFixed(USDT_PRECISION, 1);
  const formattedANY = new Decimal(String(quantity)).toFixed();

  if (symbol === 'USDT' || symbol === 'BNFCR') return formattedUSDT;

  return formattedANY;
};

export const decimalizeQuantityLocked = (subAccountAsset: SubAccountAsset) => {
  if (!subAccountAsset.locked || !subAccountAsset.quantity) return '0.000';
  if (subAccountAsset.asset.symbol === 'USDT') {
    return decimalizeQuantity(minusValues(subAccountAsset.quantity, subAccountAsset.locked), 'USDT');
  }
  const quantity = new Decimal(subAccountAsset.quantity);
  const locked = new Decimal(subAccountAsset.locked);

  return decimalizeQuantity(quantity.minus(locked).toString(), subAccountAsset.asset.symbol);
};

export const hideEmail = (email: string | undefined | null): string | undefined => {
  if (email === undefined || email === null) return undefined;

  const atIndex: number = email.indexOf('@');

  if (atIndex >= 0) {
    const username: string = email.substring(0, atIndex);
    const domain: string = email.substring(atIndex);
    const hiddenUsername: string = `${username.substring(0, Math.min(3, username.length))}***`;

    return hiddenUsername + domain;
  }

  return email;
};

export const timeElapsedInSeconds = (inputDateStr: string): number => {
  const inputDate = new Date(inputDateStr);
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - inputDate.getTime();

  const secondsElapsed = Math.floor(timeDifference / 1000);
  return secondsElapsed;
};

export const toFixedDecimal = (value: string) => {
  if (typeof value !== 'string') return '0.000';

  if (!Number.isFinite(Number(value)) || value.trim() === '') return '0.000';

  const formtValue = new Decimal(value);

  return formtValue.toFixed();
};

export const devConsoleLog = (message: string, object?: unknown): void => {
  if (window.appConfig.REACT_APP_ENV === EEnvironment.development) {
    if (object || object === 0) {
      console.log(message, object);
    } else {
      console.log(message);
    }
  }
};

export const getSubAccountName = (subAccountId: number) => {
  const subAccount = store.getState().subAccounts.subAccounts?.find((subAccount) => subAccount.id === subAccountId);

  return subAccount ? subAccount.user_name : 'Unknown';
};

export const getSubAccountImage = (id: number, exchanges: Nullable<Exchange[]>): string | undefined => {
  if (exchanges) {
    const exchange = exchanges.find((exchange) => exchange.id === id);
    return exchange ? exchange.image : undefined;
  }
  return undefined;
};

export const toFixedDecimalPrecision = (value: string, precision?: number) => {
  let result = '0.0';

  if (typeof value !== 'string') return result;

  if (!/^[-+]?[0-9]*[.,]?[0-9]+([eE][-+]?[0-9]+)?$/.test(value)) return '0.000';

  if (precision && precision === USDT_PRECISION) {
    result = new Decimal(value).toFixed(precision, 1);
  } else if (precision) {
    result = new Decimal(value).toFixed(precision, 1);
  } else if (!precision) {
    result = new Decimal(value).toFixed();
  }

  const fixedResult = new Decimal(result);
  return fixedResult.toFixed();
};

export const createSubAccounts = (subAccounts: Nullable<SubAccountType[]>): SubAccountsModify[] => {
  const subAccountsModify: SubAccountsModify[] = [];

  const byBitId = 2;

  if (!subAccounts) return subAccountsModify;

  subAccounts.forEach((subAccount) => {
    if (subAccount.exchange_id === byBitId) {
      const unifiedAccount: SubAccountsModify = {
        id: subAccount.id,
        name: subAccount.user_name,
        type: 'UNIFIED',
        is_favorite: subAccount.is_favorite,
        exchange_id: subAccount.exchange_id,
      };
      subAccountsModify.push(unifiedAccount);
    } else {
      const spotAccount: SubAccountsModify = {
        id: subAccount.id,
        name: subAccount.user_name,
        type: 'SPOT',
        is_favorite: subAccount.is_favorite,
        exchange_id: subAccount.exchange_id,
      };

      const futuresAccount: SubAccountsModify = {
        id: subAccount.id,
        name: subAccount.user_name,
        type: 'FUTURES',
        is_favorite: subAccount.is_favorite,
        exchange_id: subAccount.exchange_id,
      };

      subAccountsModify.push(spotAccount, futuresAccount);
    }
  });

  return subAccountsModify;
};

export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  // format HH:MM:SS
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

  return formattedTime;
};

export const subtractOneSecond = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);

  // Convert time to seconds
  let totalSeconds = hours * 3600 + minutes * 60 + seconds;

  totalSeconds -= 1;

  // Convert total seconds back to "hh:mm:ss" format
  const newHours = Math.floor(totalSeconds / 3600);
  const newMinutes = Math.floor((totalSeconds % 3600) / 60);
  const newSeconds = totalSeconds % 60;

  // Format the new time
  const formattedTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;

  return formattedTime;
};

export const getDate = () => {
  const currentDate = moment();

  const currentDateAsString = moment(currentDate.endOf('day')).toDate();
  const sevenDaysAgoAsString = moment(currentDate.clone().subtract(7, 'day').startOf('day')).toDate();

  return {
    sevenDaysAgoAsString,
    currentDateAsString,
  };
};

export const getUserEmail = () => {
  const userEmail = store.getState().user.user?.email;

  return userEmail || '';
};

export const getActivePageByLocation = (location: Location) => {
  const segments = location.pathname.split('/');

  if (segments[1] === 'profile' && segments.length >= 3) {
    return segments[2];
  }
  return segments[1] || START_PAGE;
};

export const formatToIsoDate = (time: string) => {
  const now = new Date();

  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');

  const [hours, minutes] = time.split(':').map((part) => part.padStart(2, '0'));
  const isoDatetime = `${year}-${month}-${day}T${hours}:${minutes}:00`;

  const localMoment = moment(isoDatetime);
  const convertFromLocalTimeToUTC = localMoment.utc().format('YYYY-MM-DDTHH:mm:ss');

  return convertFromLocalTimeToUTC;
};

export const extractTime = (isoDatetime: string | undefined) => {
  if (!isoDatetime) return '00:00';

  const utcDateStr = isoDatetime;
  const localDate = moment.utc(utcDateStr).local().format('YYYY-MM-DD HH:mm:ss');

  const date = new Date(localDate);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // formatted date to "HH:MM"
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
};

export const isDetailError = (obj: any): obj is DetailError => obj && typeof obj === 'object' && 'detail' in obj && typeof obj.detail === 'string';

export const hasOwnProperty = (obj: object, key: string): boolean => {
  try {
    return Object.prototype.hasOwnProperty.call(obj, key);
  } catch (error) {
    return false;
  }
};

export const typeSubAccountBack = (subAccountExchangeId: number): string | ETradingType => {
  if (!Number.isInteger(subAccountExchangeId)) return '';

  const { exchanges } = store.getState().exchanges;

  if (!exchanges) return '';

  const currentExchange = exchanges.find(({ id }) => id === subAccountExchangeId);

  if (!currentExchange) return '';

  return currentExchange.name.toLowerCase() === 'bybit' ? ETradingType.unified : ETradingType.spot;
};

export const typeSubAccountBackNullable = (subAccountExchangeId: number): null | ETradingType => {
  if (!Number.isInteger(subAccountExchangeId)) return null;

  const { exchanges } = store.getState().exchanges;

  if (!exchanges) return null;

  const currentExchange = exchanges.find(({ id }) => id === subAccountExchangeId);

  if (!currentExchange) return null;

  return currentExchange.name.toLowerCase() === 'bybit' ? ETradingType.unified : null;
};

export const scrollToTop = (distance?: number) => {
  window.scrollTo({
    top: distance || 0,
    behavior: 'smooth',
  });
};
export const handleSupportTelegram = async () => {
  const tokens = getTokensFromLocalStorage();

  if (!tokens) {
    window.open(TELEGRAM_BOT_SUPPORT, '_blank');
    return;
  }

  try {
    const { url } = await SupportService.telegram();

    if (typeof url === 'string') {
      window.open(url, '_blank');
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.log(error);
  }
};

export const setUserDateRange = (dateRange: DateRange, setDateRange: (ranges: string[]) => void, setPage: (page: number) => void, removeData: () => void) => {
  const currentDate = moment();
  const format = 'YYYY-MM-DD HH:mm:ss';

  const dateRangeFunctions = {
    [EDateRange.Today]: () => {
      const start = currentDate.startOf('day').format(format);
      const end = currentDate.endOf('day').format(format);
      setDateRange([start, end]);
    },
    [EDateRange.Yesterday]: () => {
      const start = currentDate.subtract(1, 'days').startOf('day').format(format);
      const end = currentDate.endOf('day').format(format);
      setDateRange([start, end]);
    },
    [EDateRange.Week]: () => {
      const start = currentDate.clone().subtract(7, 'days').startOf('day').format(format);
      const end = currentDate.endOf('day').format(format);
      setDateRange([start, end]);
    },
    [EDateRange.Month]: () => {
      const start = currentDate.subtract(31, 'days').startOf('day').format(format);
      const end = moment().endOf('day').format(format);
      setDateRange([start, end]);
    },
    [EDateRange.Year]: () => {
      const start = currentDate.subtract(365, 'days').startOf('day').format(format);
      const end = moment().endOf('day').format(format);
      setDateRange([start, end]);
    },
  };

  if (dateRangeFunctions[dateRange]) {
    dateRangeFunctions[dateRange]();
    setPage(1);
    removeData();
  } else {
    console.error('Invalid date range');
  }
};

export const clearStoreRedux = () => {
  const { dispatch } = store;

  batch(() => {
    dispatch(setAuth(false));
    dispatch(setSessionId(null));
    dispatch(setHandleConnectedWebSocket(false));
    dispatch(removeExchanges());
    dispatch(clearSubsciptionQueue());
    dispatch(clearUserSlice());
    dispatch(clearNotificationsSlice());
    dispatch(clearSubAccountsSlice());
    dispatch(clearBalancesSlice());
    dispatch(clearRebateSlice());
    dispatch(clearTradingSettingsSlice());
    dispatch(clearTransfersSlice());
    dispatch(clearAssetsSlice());
    dispatch(clearTradeListSlice());
  });
};

export const getCookie = (cookieName: string): Nullable<string> => {
  try {
    const name = `${cookieName}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i += 1) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  } catch (error) {
    console.error('Error when requesting a cookie', error);
    return null;
  }
};

export const deleteCookie = (cookieName: string, path: string = '/', domain?: string): void => {
  try {
    let cookieString = `${cookieName}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;

    if (domain) {
      cookieString += ` domain=${domain};`;
    }

    document.cookie = cookieString;
  } catch (error) {
    console.error('Error when deleting a cookie', error);
  }
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  days: number = 14, // life time
  path: string = '/',
  domain?: string,
  secure: boolean = false,
  sameSite: 'Lax' | 'Strict' | 'None' = 'Lax',
): void => {
  try {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Добавляем время жизни куки

    let cookieString = `${cookieName}=${encodeURIComponent(cookieValue)}; expires=${date.toUTCString()}; path=${path};`;

    if (domain) {
      cookieString += ` domain=${domain};`;
    }

    if (secure) {
      cookieString += ' secure;';
    }

    cookieString += ` SameSite=${sameSite};`;

    document.cookie = cookieString;
  } catch (error) {
    console.error('Error when setting a cookie', error);
  }
};
