export const ROUTE_PATH = {
  login: {
    login: '/api/auth/login',
    sendCodePhone: '/api/auth/login/phone/send-code',
    sendCodeWhatsApp: '/api/auth/login/whatsapp/send-code',
  },
  registration: {
    step1: '/api/auth/registration/step1',
    step2: '/api/auth/registration/step2',
    registration: '/api/auth/registration',
    sendCodeEmail: 'api/auth/registration/email/send-code',
    sendCodePhone: 'api/auth/registration/phone/send-code',
    sendCodeWhatsApp: 'api/auth/registration/whatsapp/send-code',
  },
  forgotPassword: {
    stepOne: '/api/auth/forgot-password/step1',
    stepTwo: '/api/auth/forgot-password/step2',
    stepThree: '/api/auth/forgot-password',
    sendCodeEmail: 'api/auth/forgot-password/email/send-code',
    sendCodePhone: 'api/auth/forgot-password/phone/send-code',
    sendCodeWhatsApp: 'api/auth/forgot-password/whatsapp/send-code',
  },
  notifications: '/api/users/notifications',
  users: {
    me: '/api/users/me',
    exist: '/api/users/exist',
    whiteWallets: '/api/users/white-wallets',
    walletsAttributes: '/api/users/white-wallets/attributes',
    settings: {
      ga: '/api/users/settings/ga',
      kyc: '/api/users/settings/kyc',
      email: '/api/users/settings/email',
      yubikey: '/api/users/settings/yubikey',
      discord: '/api/users/settings/discord',
      theme: '/api/users/settings/theme',
      typeSms: '/api/users/settings/type-sms',
      settings: '/api/users/settings/',
      telegram: '/api/users/settings/telegram',
      loginVerify: '/api/users/settings/login-verifications/',
      phoneNumber: '/api/users/settings/phone-number',
      changePassword: '/api/users/settings/password',
      accountActionVerify: '/api/users/settings/account-action-verifications',
      tableColumnsSave: '/api/users/settings/table-save',
    },
    ipAddress: '/api/users/ip-addresses',
    delete: '/api/users/delete',
    bonus: {
      history: '/api/users/bonuses/history',
    },
    tradeSettings: '/api/users/trade-settings',
    tradeCategories: '/api/users/trade-categories',
    rebates: {
      settings: '/api/users/rebates/settings',
    },
  },

  verifications: {
    confirm: '/api/verifications/confirm',
    confirgGA: '/api/verifications/confirm/ga',
    verification: '/api/verifications',
    sendEmailCode: '/api/verifications/email/send-code',
    sendPhoneCode: '/api/verifications/phone/send-code',
    sendWhatsAppCode: '/api/verifications/whatsapp/send-code',
  },
  instrument: {
    search: '/api/instruments/search',
    symbols: '/api/instruments/symbols',
    searchSymbols: '/api/instruments/search/symbols',
  },
  kyc: '/api/kyc',
  verify: '/api/auth/verification',
  existsUser: '/api/users/exists',
  refreshToken: '/api/refresh-tokens',
  accessRecover: '/api/auth/access-recovery',
  assets: {
    assets: '/api/assets',
    networks: '/api/assets/networks',
    allNetworks: '/api/networks',
    allNetworksSearch: '/api/networks/search',
    assetsSearch: '/api/assets/search',
    walletAddress: '/api/assets/networks/wallet-address',
  },
  networksAssets: '/api/networks/assets',
  exchanges: '/api/exchanges/',
  subAccounts: {
    assets: 'api/sub-accounts/assets',
    setCollateralStatus: '/api/sub-accounts/assets/set-collateral-status',
    balance: '/api/sub-accounts/balances',
    secrets: '/api/sub-accounts/secrets',
    setting: '/api/sub-accounts/settings',
    subAccounts: '/api/sub-accounts/',
    dustTransforms: {
      transforms: '/api/sub-accounts/dust-transforms',
      assets: '/api/sub-accounts/dust-transforms/assets',
      countdown: '/api/sub-accounts/dust-transforms/countdown',
      history: '/api/sub-accounts/dust-transforms/history',
    },
    rebates: '/api/sub-accounts/rebates/history',
    rebatesStatistics: '/api/sub-accounts/rebates/statistics',
    resetPnl: '/api/sub-accounts/balances/reset-pnl',
  },
  leverages: {
    leverages: '/api/leverages',
    calculate: '/api/leverages/calculate',
    marginMode: '/api/leverages/margin-mode',
    autoCalulate: '/api/leverages/auto-calculate',
  },
  positions: {
    get: '/api/positions',
    pnl: '/api/positions/pnl',
    close: '/api/positions/close',
    reduce: '/api/positions/reduce',
    summary: '/api/positions/summary/list',
    summaryId: '/api/positions/summary/id',
    summaryCategory: '/api/positions/summary/categories',
    closeAll: 'api/positions/close-all',
    visability: '/api/positions/visability',
  },
  orders: {
    get: '/api/orders',
    close: '/api/orders/close',
    closeAll: '/api/orders/close-all',

  },
  withdrawals: '/api/withdrawals',
  withdrawalAttributess: '/api/withdrawals/attributes',
  transfers: '/api/transfers',
  support: {
    telegram: '/api/support/telegram',
  },
  screener: {
    versions: '/api/volume-screener/get-available-releases',
    downloadRelease: '/api/volume-screener/download-release',
  },
  candles: 'api/candles',
} as const;
