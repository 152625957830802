import {
  useEffect, useRef, useState, FormEventHandler,
  useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Carousel } from '@mantine/carousel';
import { Modal } from '@mantine/core';
import {
  fetchChangeUserDiscord,
  ChangeUserDiscordParams,
  fetchChangeUserTelegram,
  ChangeUserTelegramParams,
  fetchUserRebatesSettings,
} from 'src/app/store/slices/user/thunks';
import { selectUser } from 'src/app/store/slices/user/selectors';
import { useAppDispatch } from 'src/app/store/store';
import { removeVerifications } from 'src/app/store/slices/verifications/slice';
import { selectVerifications } from 'src/app/store/slices/verifications/selectors';
import {
  Confirm,
  fetchVerifications,
  VerificationConfirm,
  fetchVerificationsConfirm,
  VerificationsSendEmailCode,
  VerificationsSendPhoneCode,
  fetchVerificationsSendEmailCode,
  fetchVerificationsSendPhoneCode,
  VerificationsSendWhatsAppCode,
  fetchVerificationsSendWhatsAppCode,
} from 'src/app/store/slices/verifications/thunks';
import { EVerificationType, Verification } from 'src/app/store/slices/verifications/types';
import { handleSupportTelegram, hideEmail } from 'src/shared/libs/helpers/helper.lib';
import { useChangePageTitle } from 'src/shared/libs/hooks/use-change-page-title';

import Hint from 'src/shared/ui/help/hint';
import CopyIcon from 'src/shared/assets/icons/copy-icon/copy-icon';
import Button from 'src/shared/ui/button/button/button';
import useAlert from 'src/shared/libs/hooks/use-alert';
import useMobile from 'src/shared/libs/hooks/useMobile';
import InputCode from 'src/shared/ui/input/input-code/input-code';
import InputForm from 'src/shared/ui/input/input-form/input-form';
import LoadingPageState from 'src/entities/loading-page-state/loading-page-state';

import { EStatus } from 'src/shared/types/global-types';
import { ReactComponent as DiscordIconSvg } from 'src/shared/assets/images/socials/discord.svg';
import { ReactComponent as TelegramIconSvg } from 'src/shared/assets/images/socials/telegram.svg';
import { ReactComponent as VerifyIconSvg } from 'src/shared/assets/images/user-verify.svg';
import Slider from './components/slider/slider';

import RebateWidget from './components/rebate-widget/rebate-widget';
import InputTextWithIcon from '../../shared/ui/input/input-text-with-icon/input-text-with-icon';
import InputAction from '../auth/components/inputs-auth/input-action';

import './profile.scss';

const pageTitle = 'Профиль';
const partnerUrl = `${window.origin}?partner=`;

const ProfilePage = () => {
  useChangePageTitle(pageTitle);
  const isMobile = useMobile();
  const dispatch = useAppDispatch();
  const { setAlertMessage } = useAlert();

  console.log();

  const {
    user, status: userStatus, statusDiscord, statusTelegram, settings, rebates,
  } = useSelector(selectUser);

  const { verification, statusConfirm } = useSelector(selectVerifications);
  const [openedVerify, { open: openVerify, close: closeVerify }] = useDisclosure(false);

  // verify modal from states
  const [codeEmail, setCodeEmail] = useState('');
  const [codePhone, setCodePhone] = useState('');
  const [codeGA, setCodeGA] = useState('');

  const verifyFormRef = useRef<HTMLFormElement>(null);

  const [verifyInputError, setVerifyInputError] = useState({
    emailError: false,
    phoneError: false,
    gaError: false,
  });

  const [discordValue, setDiscordValue] = useState('');
  const [discordChange, setDiscordChange] = useState(true);
  const [discordChangeStatus, setDiscordChangeStatus] = useState(false);
  const [discordValueTimer, setDiscordValueTimer] = useState(false);

  const [telegramValue, setTelegramValue] = useState('');
  const [telegramChange, setTelegramChange] = useState(true);
  const [telegramChangeStatus, setTelegramChangeStatus] = useState(false);
  const [telegramValueTimer, setTelegramValueTimer] = useState(false);

  const [editInput, setEditInput] = useState('');

  const resetInputsValue = () => {
    setCodeEmail('');
    setCodePhone('');
    setCodeGA('');
    setVerifyInputError({
      emailError: false,
      phoneError: false,
      gaError: false,
    });
  };

  const handleFetchVerification = async (verificationType: EVerificationType) => {
    let result: string | boolean = false;

    const { payload } = await dispatch(fetchVerifications(verificationType));
    const responseVerification = payload as Verification;

    if (payload === 'TOO_MANY_REQUESTS') {
      result = payload;
      return result;
    }

    if (responseVerification && user) {
      const sendPhoneCodeParams: VerificationsSendPhoneCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
        phoneNumber: user.phone_number,
        verification_id: responseVerification.verification_id,
      };

      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: responseVerification.verification_id,
      };

      if (responseVerification.methods.includes('phone')) {
        const { payload } = await dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('whatsapp')) {
        const { payload } = await dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));

        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('email')) {
        dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
        if (payload) {
          result = true;
        }
      }
      if (responseVerification.methods.includes('ga')) {
        if (payload) {
          result = true;
        }
      }
    } else {
      setAlertMessage('Произошла внутренняя ошибка', 'error');
    }

    return result;
  };

  const onSaveDiscord = async () => {
    if (verification) {
      const changeUserDiscrodParams: ChangeUserDiscordParams = {
        discord_name: discordValue,
        verification_id: verification.verification_id,
      };

      const responseChangeDiscord = await dispatch(fetchChangeUserDiscord(changeUserDiscrodParams));

      if (responseChangeDiscord.payload) {
        setDiscordValueTimer(false);
        localStorage.removeItem('discordValueTimer');
        setDiscordChange(true);

        setAlertMessage('Данные успешно изменены', 'success');
        dispatch(removeVerifications());
      } else {
        setDiscordChange(true);
        setDiscordValueTimer(false);
        localStorage.removeItem('discordValueTimer');

        setAlertMessage('Произошла внутренняя ошибка', 'error');
        dispatch(removeVerifications());
      }
    }
  };

  const handleSetDiscordChange = async () => {
    setDiscordChangeStatus(true);

    const responseStatus = await handleFetchVerification(EVerificationType.CHANGE_DISCORD);

    if (responseStatus) {
      openVerify();
      setDiscordValueTimer(true);
      setDiscordChangeStatus(false);
      setEditInput('discord');
    } else {
      setDiscordValueTimer(false);
      setDiscordChangeStatus(false);
    }
  };

  const onSaveTelegram = async () => {
    if (verification) {
      const changeUserTelegramParams: ChangeUserTelegramParams = {
        telegram_name: telegramValue,
        verification_id: verification.verification_id,
      };

      const responseChangeTelegram = await dispatch(fetchChangeUserTelegram(changeUserTelegramParams));

      if (responseChangeTelegram.payload || responseChangeTelegram.payload === '') {
        setTelegramValueTimer(false);
        localStorage.removeItem('telegramValueTimer');
        setTelegramChange(true);

        setAlertMessage('Данные успешно изменены', 'success');
        dispatch(removeVerifications());
      } else {
        setTelegramChange(true);
        setTelegramValueTimer(false);
        localStorage.removeItem('telegramValueTimer');

        setAlertMessage('Произошла внутренняя ошибка', 'error');
        dispatch(removeVerifications());
      }
    }
  };

  const handleSetTelegramChange = async () => {
    setTelegramChangeStatus(true);
    setEditInput('telegram');

    const responseStatus = await handleFetchVerification(EVerificationType.CHANGE_TELEGRAM);

    if (responseStatus) {
      openVerify();
      setTelegramValueTimer(true);
      setTelegramChangeStatus(false);
    } else {
      setTelegramValueTimer(false);
      setTelegramChangeStatus(false);
    }
  };

  const handleFetchCodeSms = () => {
    if (user && verification && settings) {
      if (settings.type_sms_code === 'phone') {
        const sendPhoneCodeParams: VerificationsSendPhoneCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };

        dispatch(fetchVerificationsSendPhoneCode(sendPhoneCodeParams));
      } else {
        const sendWhatsAppParams: VerificationsSendWhatsAppCode = {
          phoneNumber: user.phone_number,
          verification_id: verification.verification_id,
        };

        dispatch(fetchVerificationsSendWhatsAppCode(sendWhatsAppParams));
      }
    }
  };

  const handleFetchCodeEmail = () => {
    if (user && verification) {
      const sendEmailCodeParams: VerificationsSendEmailCode = {
        email: user.email,
        verification_id: verification.verification_id,
      };

      dispatch(fetchVerificationsSendEmailCode(sendEmailCodeParams));
    }
  };

  const handleCancelModal = () => {
    resetInputsValue();
    closeVerify();

    if (editInput === 'discord') {
      setDiscordChange(true);
      setDiscordValueTimer(true);
      setDiscordChangeStatus(false);
    }

    if (editInput === 'telegram') {
      closeVerify();
      setTelegramChange(true);
      setTelegramValueTimer(true);
      setTelegramChangeStatus(false);
    }
  };

  const handleSubmitVerification: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }

      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (!responseConfirm.payload) {
          if (verification) {
            if (verification.methods.includes('email')) {
              setVerifyInputError((prev) => (
                { ...prev, emailError: true }
              ));
            }
            if (verification.methods.includes('phone')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('whatsapp')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('ga')) {
              setVerifyInputError((prev) => (
                { ...prev, gaError: true }
              ));
            }
          }
        } else {
          closeVerify();

          if (editInput === 'discord') onSaveDiscord();
          if (editInput === 'telegram') onSaveTelegram();

          resetInputsValue();
        }
      }
    }
  };

  const handleAutoSubmit = async () => {
    let checkSaveForm = false;
    const form = verifyFormRef.current;

    if (verification) {
      if (verification.methods.includes('email')) {
        setVerifyInputError((prev) => (
          { ...prev, emailError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('phone')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codeEmail.trim() === '' }
        ));
      }
      if (verification.methods.includes('whatsapp')) {
        setVerifyInputError((prev) => (
          { ...prev, phoneError: codePhone.trim() === '' }
        ));
      }

      if (verification.methods.includes('ga')) {
        setVerifyInputError((prev) => (
          { ...prev, gaError: codeGA.trim() === '' }
        ));
      }
    }

    if (form) {
      const inputElements = form.querySelectorAll('input');

      // Convert NodeList to an array
      const inputsArray = Array.from(inputElements);

      // Check that all inputs are filled (trim() is used to remove spaces).
      checkSaveForm = inputsArray.every((input) => input.value.trim() !== '');
    }

    if (checkSaveForm) {
      let codes: Confirm = {};

      if (verification) {
        if (verification.methods.includes('phone')) {
          codes.sms_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('whatsapp')) {
          codes.whatsapp_verification_code = Number(codePhone);
        }
        if (verification.methods.includes('email')) {
          codes.email_verification_code = Number(codeEmail);
        }
        if (verification.methods.includes('ga')) {
          codes.ga_verification_code = String(codeGA);
        }

        const verificationParams: VerificationConfirm = {
          confirm: {
            ...codes,
          },
          verification_id: verification.verification_id,
        };

        const responseConfirm = await dispatch(fetchVerificationsConfirm(verificationParams));

        if (!responseConfirm.payload) {
          if (verification) {
            if (verification.methods.includes('email')) {
              setVerifyInputError((prev) => (
                { ...prev, emailError: true }
              ));
            }
            if (verification.methods.includes('phone')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('whatsapp')) {
              setVerifyInputError((prev) => (
                { ...prev, phoneError: true }
              ));
            }
            if (verification.methods.includes('ga')) {
              setVerifyInputError((prev) => (
                { ...prev, gaError: true }
              ));
            }
          }
        } else {
          closeVerify();

          if (editInput === 'discord') onSaveDiscord();
          if (editInput === 'telegram') onSaveTelegram();

          resetInputsValue();
        }
      }
    }
  };

  const renderVerificationForm = (methods: string[]) => {
    const formElements: React.ReactNode[] = [];

    if (methods.includes('email')) {
      formElements.push(
        <div key="email-input" className="verification-input">
          <InputAction
            label="Код из Письма"
            placeholder="Код"
            type="number"
            actionTitle="Отправить код"
            onClick={handleFetchCodeEmail}
            actionActive
            error={verifyInputError.emailError}
            {...{
              value: codeEmail,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodeEmail(e.target.value),
            }}
          />
          <span className="verification-input-text">Код подтверждения будет отправлен на {hideEmail(user?.email)}</span>
        </div>,
      );
    }

    if (methods.includes('phone')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из СМС"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('whatsapp')) {
      formElements.push(
        <div key="sms-input" className="verification-input">
          <InputAction
            label="Код из WhatsApp"
            placeholder="Код"
            actionTitle="Отправить код"
            actionActive
            onClick={handleFetchCodeSms}
            type="number"
            error={verifyInputError.phoneError}
            {...{
              value: codePhone,
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCodePhone(e.target.value),
            }}
          />
        </div>,
      );
    }

    if (methods.includes('ga')) {
      formElements.push(
        <div key="ga-input" className="verification-input">
          <InputForm title="Код Google Authenticator">
            <InputCode focus={verification?.methods.every((method) => method === 'ga')} error={verifyInputError.gaError} onChange={setCodeGA} />
          </InputForm>
        </div>,
      );
    }

    formElements.push(
      <div key="modal-hint" className="verification-input">
        <Hint
          hintOpen
          position="bottom-start"
          text="Возникли проблемы с верификацией?"
          hintText={(
            <div>
              Код с почты не подходит:
              <br />
              - Возможно, срок действия кода истек, запросите новый код по кнопке - Отправить код
              <br />
              - Убедитесь в корректности почты
              <br />
              <br />
              Код Google Autenticator не подходит:
              <br />
              - Синхронизируйте время между устройствами. Если вы используете GA на Андроид, воспользуйтесь функцией - Синхронизация времени
              <br />
              - Убедитесь что вы вводите код для аккаунта league.broker
              <br />
              <br />
              Если какой то из кодов по прежнему не подходит, обратитесь в тех. поддержку <button onClick={handleSupportTelegram}>Телеграмм</button>
            </div>
          )}
        />
      </div>,
    );

    formElements.push(
      <div key="verify-modal-action" className="verify-modal-action">
        <Button
          disabled={statusConfirm === EStatus.loading}
          loading={statusConfirm === EStatus.loading}
          type="submit"
          background="green"
        >Подтвердить
        </Button>
        <Button disabled={statusConfirm === EStatus.loading} onClick={handleCancelModal} background="gray">Отмена</Button>
      </div>,
    );

    return <form ref={verifyFormRef} className="verification-form-wrapper" onSubmit={handleSubmitVerification}>{formElements}</form>;
  };

  useLayoutEffect(() => {
    dispatch(fetchUserRebatesSettings());
  }, []);

  useEffect(() => {
    if (user) {
      setDiscordValue(user.discord_name);
      setTelegramValue(user.telegram_name || '');
    }
  }, [user]);

  useEffect(() => {
    if (verification) {
      const onlyGa = verification.methods.every((method) => method === 'ga') && verification.methods.includes('ga');
      const onlyPhone = verification.methods.every((method) => method === 'phone') && verification.methods.includes('phone');
      const onlyWhatsApp = verification.methods.every((method) => method === 'whatsapp') && verification.methods.includes('whatsapp');
      const hasEmailAndWhatsapp = verification.methods.includes('email') && verification.methods.includes('whatsapp');
      const hasEmailAndPhone = verification.methods.includes('email') && verification.methods.includes('phone');

      if (onlyGa) {
        if (codeGA.length === 6) {
          handleAutoSubmit();
        }
      }

      if (onlyWhatsApp) {
        if (codePhone.length === 4) {
          handleAutoSubmit();
        }
      }

      if (onlyPhone) {
        if (codePhone.length === 5) {
          handleAutoSubmit();
        }
      }

      if (hasEmailAndWhatsapp) {
        if (codeEmail.length === 5 && codePhone.length === 4) {
          handleAutoSubmit();
        }
      }

      if (hasEmailAndPhone) {
        if (codeEmail.length === 5 && codePhone.length === 5) {
          handleAutoSubmit();
        }
      }
    }
  }, [codeGA, codeEmail, codePhone, verification]);

  if (!user && userStatus === EStatus.loading) {
    return <LoadingPageState />;
  }

  if (!user && userStatus === EStatus.rejected) {
    return (
      <div className="loading-wrapper">
        Произошла внутренняя ошибка при получении данных пользователя
        <br />
        Попробуйте обновить старницу
      </div>
    );
  }

  return (
    <div className="profile">
      <div className="section-name">
        <h1 className="section-name-title">Мой профиль</h1>

        <div className="section-user-id-copy">
          <span className="user-id-title">UserID</span>
          <div className="user-id-wrapper">
            <div className="user-id-value">{user?.user_id || 'Нет данных'}</div>
            <CopyIcon value={`${partnerUrl}${user?.user_id}`} />
          </div>
        </div>
      </div>

      <div className="section-content">
        <div className="public-info-wrapper">
          <div className="public-info">
            <h3>{user?.full_name}
              {user?.is_verified && <VerifyIconSvg />}
            </h3>
            <span className="user-role">Трейдер сообщества</span>

            <div className="socials">
              <InputTextWithIcon
                IconContent={DiscordIconSvg}
                value={discordValue}
                buttonText="Изменить"
                onEdit={() => setDiscordChange(false)}
                onSave={handleSetDiscordChange}
                isDisabled={discordChange}
                onChange={setDiscordValue}
                placeholder="Discrod"
                positionLoading="center"
                loading={statusDiscord === EStatus.loading || discordChangeStatus}
                disabled={statusDiscord === EStatus.loading || discordChangeStatus}
                focus
                startTimer={discordValueTimer}
                stopTimer={setDiscordValueTimer}
                timerId="discordValueTimer"
              />

              <InputTextWithIcon
                IconContent={TelegramIconSvg}
                value={telegramValue}
                buttonText="Изменить"
                onEdit={() => setTelegramChange(false)}
                onSave={handleSetTelegramChange}
                isDisabled={telegramChange}
                onChange={setTelegramValue}
                placeholder="Telegram"
                positionLoading="center"
                loading={statusTelegram === EStatus.loading || telegramChangeStatus}
                disabled={statusTelegram === EStatus.loading || telegramChangeStatus}
                focus
                startTimer={telegramValueTimer}
                stopTimer={setTelegramValueTimer}
                timerId="telegramValueTimer"
              />
            </div>
          </div>

          {isMobile && <h2 className="rebate-title">Условия кэшбэка</h2>}

          <div className="public-info-widget">
            {isMobile && (
              <Carousel
                slideSize="100%"
                height={260}
                slideGap="xs"
                align="start"
                controlsOffset="md"
                controlSize={14}
                withControls={false}
                withIndicators
              >
                {rebates?.settings?.map((exchange) => (
                  <Carousel.Slide key={exchange.exchange_id}>
                    <RebateWidget exchange={exchange} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            )}

            {!isMobile && (
              rebates?.settings?.map((exchange) => (
                <RebateWidget key={exchange.exchange_id} exchange={exchange} />
              ))
            )}
          </div>

        </div>

        <Slider />

        <Modal
          title="Подтвердите действие"
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 3,
          }}
          radius={16}
          size="sm"
          opened={openedVerify}
          onClose={handleCancelModal}
          closeOnClickOutside={false}
          closeOnEscape={false}
          className="modal-custom-overflow"
        >
          <div>
            {verification && renderVerificationForm(verification.methods)}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ProfilePage;
