import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import { AxiosError, AxiosResponse } from 'axios';
import {
  AssetData, Assets, InstrumentsSymbolsData, Networks, SearchAssetType, WalletAddress, WalletAttributes,
} from './types';

export type fetchWalletAddressParams = {
  assetId: number;
  networkId: number;
  subAccountId: number;
}

export type Withdrawal = {
  asset_symbol: string
  from_sub_account_id: number
  white_wallet_id: number
  verification_id: number
  quantity: number
}

export type AssetsParams = {
  asset_trading_type: string
  limit: number
  page: number
  is_active_to_withdrawal?: boolean
  is_active_to_deposit?: boolean
  network_id?: number
  exchange_id?: number
}

export type SearchAssetsParamsType = {
  symbols?: string[]
  query?: string
  asset_ids?: number[]
  asset_trading_type?: string
  exchange_id?: number
  is_active_to_deposit?: boolean
}

export type NewtrorksAssetsParams = {
  network_id?: number
}

export type PaginationParams = {
  page: number
  limit: number
  exchange_id?: number
}

export type NetworksParams = {
  asset_id: number,
  is_active_to_deposit: boolean
}

export type WalletAttributesParams = {
  wallet_id: number,
  exchange_id: number
}

export const fetchAssets = createAsyncThunk(
  'assets/fetchAssets',
  async (assetParams: AssetsParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<AssetData>(ROUTE_PATH.assets.assets, {
        params: assetParams,
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchNetworksAssets = createAsyncThunk(
  'assets/fetchNetworksAssets',
  async (params: NewtrorksAssetsParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<Assets[]>(ROUTE_PATH.networksAssets, {
        params,
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchAssetsFutures = createAsyncThunk(
  'assets/fetchAssetsFutures',
  async (exchangeId: number, { rejectWithValue }) => {
    try {
      const response = await $api.get<AssetData>(ROUTE_PATH.assets.assets, {
        params: {
          asset_trading_type: 'FUTURES',
          limit: 20,
          page: 1,
          exchange_id: exchangeId,
        },
      });
      const { data } = response;
      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchSearchAssets = createAsyncThunk(
  'assets/fetchSearchAssets',
  async (searchAssetsParams: SearchAssetsParamsType, { rejectWithValue }) => {
    try {
      const response = await $api.post<SearchAssetType[]>(ROUTE_PATH.assets.assetsSearch, searchAssetsParams);
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchNetworks = createAsyncThunk(
  'assets/fetchNetworks',
  async (params: NetworksParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<Networks[]>(ROUTE_PATH.networksAssets, { params });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchAllNetworks = createAsyncThunk(
  'assets/fetchAllNetworks',
  async (params: PaginationParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(ROUTE_PATH.assets.allNetworks, {
        params,
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchAllNetworksSearch = createAsyncThunk(
  'assets/fetchAllNetworksSearch',
  async (query: string, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.assets.allNetworksSearch, { query });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchWalletAddress = createAsyncThunk(
  'assets/fetchWalletAddress',
  async ({ assetId, networkId, subAccountId }: fetchWalletAddressParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<WalletAddress>(ROUTE_PATH.assets.walletAddress, {
        params: {
          asset_id: assetId,
          network_id: networkId,
        },
        headers: {
          'sub-account-id': Number(subAccountId),
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchWalletAttributes = createAsyncThunk(
  'assets/fetchWalletAttributes',
  async (params: WalletAttributesParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<WalletAttributes>(ROUTE_PATH.users.walletsAttributes, { params });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchWithdrawals = createAsyncThunk(
  'assets/fetchWithdrawals',
  async (withdrawalOptions: Withdrawal, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<boolean | string> = await $api.post(ROUTE_PATH.withdrawals, withdrawalOptions);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchInstrumentSymbols = createAsyncThunk(
  'assets/fetchInstrumentSymbols',
  async (params: PaginationParams, { rejectWithValue }) => {
    try {
      const response = await $api.get<InstrumentsSymbolsData>(ROUTE_PATH.instrument.symbols, {
        params,
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);

export const fetchInstrumentSymbolsSearch = createAsyncThunk(
  'assets/fetchInstrumentSymbolsSearch',
  async (instrumentSymbol: string, { rejectWithValue }) => {
    try {
      const response = await $api.post<string[]>(ROUTE_PATH.instrument.searchSymbols, null, {
        params: {
          query: instrumentSymbol,
        },
      });
      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error);
    }
  },
);
